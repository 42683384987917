import React from 'react';

import Seo from '../components/Seo';

import sections from '../constants/sections';

function FifthPosition() {
  return <Seo title={sections.FifthPosition.title} />;
}

export default FifthPosition;
